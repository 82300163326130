import axios from 'axios'

// const url_backend = `http://127.0.0.1:8000`
// const url_backend = `https://allianceformacion-api.smartbits-es.com`
const url_backend = `https://allianceformacion2-api.smartbits-es.com`
// const url_backend = `https://allianceformacion3-api.smartbits-es.com`
//const url_backend = `https://allianceformacion-api.smartbits.es`

//export const apiScorm = 'https://cloud.scorm.com/lrs/LJ0MVL8X14/';
//export const apiScorm = 'https://cloud.scorm.com/EngineWebServices/api';
// export const apiScorm = 'https://demosmb.talentlms.com/api';

export const url_media = `${url_backend}/api/media/documents`
export const url_empresa = `${url_backend}/api/media/enterprise`
export const url_certificados = `${url_backend}/api/media/certificados`
export const url_mensajes = `${url_backend}/api/media/mensajes`
export const url_back = `${url_backend}`
export const loginURL = `${url_backend} /login`
export const random = {
    color: function () {
        const colors = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];
        const random = Math.floor(Math.random() * colors.length);
        return colors[random]
    }
}

export const scorm = {
    users: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/scorm/users`)
    },
    cursos: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/scorm/cursos`)
    },
    upload:  function (formdata, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/localscorm/store`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    }
}

export const moodle = {
    users: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/moodle/users`)
    },
    cursos: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/moodle/cursos`)
    },
    newUser: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/moodle/newUser`)
    },
    newCurso: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/moodle/newCurso`)
    }
}

export const auth = {
    loginAdm: function (email, password) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/login`, {
            email: email.toString(),
            password: password.toString()
        })
    },
    forgotPass: function (email) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/forgot-password`, {
            email: email
        })
    },
    resetPass: function (token, email, password, password_confirmation) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/reset-password`, {
            token: token,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        })
    },
    logout: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/logout`)
    },
    editUser: function (id, name, apellidos, email, email_secundario, telefono) {
        const user = {
            name: name,
            apellidos: apellidos,
            email: email,
            email_secundario: email_secundario,
            telefono: telefono
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/perfil/edit/${id}`, user)
    },
    cambioPass: function (id, password, new_password) {
        const user = {
            password: password,
            new_password: new_password
        }
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend + `/api/perfil/changepass/1`, user)
    },
    verImg: function(url) {
        return axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        })
    },
    verImgAuth: function(url) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        })
    }


}

export const userProfile = {
    getProfile: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/auth/user-profile`)
    }
}

export const dashboardData = {
    getTotalLogin: function (mes, anio) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/dashboard/totalLogin`, {
            mes: mes,
            anio: anio
        })
    },
    getInfoDashboard: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/dashboard/infoDashboard`)
    }
}

export const userData = {
    getUsers: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/users`)
    },
    getUsersReducido: function (limit, data) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/usersReducido/${limit}`, data)
    },
    getUsersEliminados: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/usersEliminados`)
    },
    getUsersEliminadosReducido: function (limit, data) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/usersEliminadosReducido/${limit}`, data)
    },
    getUsersValidar: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/usersValidar`)
    },
    getAdministradores: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/admins`)
    },
    getStudents: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/students`)
    },
    getTutores: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/tutores`)
    },
    getAuditores: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/auditores`)
    },
    getUser: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/user/${id}`)
    },
    getUserDni: function (dni) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            dni: dni
        }
        return axios.post(url_backend + `/api/users/userdni`, datos)
    },
    getUserInfo: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/userget/${id}`)
    },
    getRoles: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/roles`)
    },
    newUser: function (name, apellidos, dni, email, email_secundario, telefono, password, password_confirmation, rol, enterprise, horaInicio, horaFin, diasAcc, puesto, envio,validado) {
        const datos = {
            name: name,
            apellidos: apellidos,
            dni: dni,
            email: email,
            email_secundario: email_secundario,
            telefono: telefono,
            password: password == '' ? dni : password,
            password_confirmation: password == '' ? dni : password_confirmation,
            rol: rol,
            hora_inicio: horaInicio,
            hora_fin: horaFin,
            dias_acceso: diasAcc.toString(),
            enterprise: enterprise,
            logo: localStorage.getItem("logo"),
            envio_pass: envio,
            puesto: puesto,
            validado:validado
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/register`, datos)
    },
    newUserPasaporte: function (name, apellidos, email, email_secundario, telefono, password, password_confirmation, rol, enterprise, horaInicio, horaFin, diasAcc, puesto, envio,pasaporte) {
        const datos = {
            name: name,
            apellidos: apellidos,
            email: email,
            email_secundario: email_secundario,
            telefono: telefono,
            password: password == '' ? pasaporte : password,
            password_confirmation: password == '' ? pasaporte : password_confirmation,
            rol: rol,
            hora_inicio: horaInicio,
            hora_fin: horaFin,
            dias_acceso: diasAcc.toString(),
            enterprise: enterprise,
            logo: localStorage.getItem("logo"),
            envio_pass: envio,
            puesto: puesto,
            pasaporte:pasaporte
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/registerPasaporte`, datos)
    },
    enviarClaves(id, mail) {
        const datos = {
            id: id,
            email: mail,
            logo: localStorage.getItem("logo")
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/envioClaves`, datos)
    },
    enviarClavesMulti(seleccionados) {
        const datos = {
            seleccionados: seleccionados,
            logo: localStorage.getItem("logo")
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/auth/envioClavesMulti`, datos)
    },
    enviarClavesBienvenido(seleccionados) {
        const datos = {
            seleccionados: seleccionados,
        }
        return axios.post(url_backend + `/api/matriculas/enviarClavesBienvenido`, datos)
    },
    enviarClavesBienvenidoAvsaf(seleccionados) {
        const datos = {
            seleccionados: seleccionados,
        }
        return axios.post(url_backend + `/api/matriculas/enviarClavesBienvenidoAvsaf`, datos)
    },
    relationUserEnterprise(id, enterprises) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/userenterprise/${id}`, {
            enterprises: enterprises
        })
    },

    updateuserValidado(id, validado) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/updateuserValidado/${id}`, {
    
            validado:validado
        })
    },

    updateUser(id, nombre, apellido, dni, mail, mailSecundario, telefono, hinicio, hfin, empresa, puesto, rol, intransferible_pass) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/updateuser/${id}`, {
            name: nombre,
            apellidos: apellido,
            dni: dni,
            email: mail,
            email_secundario: mailSecundario,
            telefono: telefono,
            hora_inicio: hinicio,
            hora_fin: hfin,
            enterprise: empresa,
            puesto: puesto,
            rol: rol,
            intransferible_pass:intransferible_pass
        })
    },

    borrarUser(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/delete/${id}`)
    },
    restoreUser(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/restore/${id}`)
    },
    bloquearUsuario(id, block) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/block/${id}`, { valor: block })
    },

    dameMensajes(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/mensajes/dameMensajes/${id}`)
    },
    oldUser() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/users/studentsHeader`)
    },
    import(formdata, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/import`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    import2(formdata, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/import2`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    import3(formdata, empresa, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/import3/${empresa}`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    importUsers(usuarios, envio) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/importUsers`, { datos: usuarios, envio })
    },
    importUsersEnterprises(usuarios, envio, empresa, empresaId) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/importUsersEnterprise`, { datos: usuarios, envio, empresa, empresaId })
    },
    exportar: function (objeto) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objeto: objeto,
        }
        return axios({
            url: url_backend + `/api/users/exportarExcel`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    saveSoportePwd: function (id, password) {

        const data = {
            id: id,
            password: password
        }
        return axios.post(url_backend + `/api/users/soportepwd`, data)
    },
    getIp: function () {
        return axios.post(url_backend + `/api/users/getIp`)
    }
}

export const enterprisesData = {
    getEnterprises: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterprises`)
    },
    getEnterprisesReducido: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterprisesReducido`)
    },
    getEnterprise: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterprise/${id}`)
    },
    getEnterpriseInfo: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterpriseInfo/${id}`)
    },
    getEnterpriseMat: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterpriseMat/${id}`)
    },
    getEnterpriseUsers: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterpriseUsers/${id}`)
    },
    newEnterprise: function (name, direction, cif, phone, person_contact, email_contact, logo, url_personal, url, urlAdmin, observaciones) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/enterprises/enterprise`, {
            name: name,
            direction: direction,
            cif: cif,
            phone: phone,
            person_contact: person_contact,
            email_contact: email_contact,
            logo: logo,
            url_personal: url_personal,
            url: url,
            url_admin: urlAdmin,
            observaciones: observaciones
        })
    },
    updateEnterprise: function (id, name, direction, cif, phone, person_contact, email_contact, logo, url_personal, url, urlAdmin, observaciones) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/enterprises/enterprise/${id}`, {
            name: name,
            direction: direction,
            cif: cif,
            phone: phone,
            person_contact: person_contact,
            email_contact: email_contact,
            logo: logo,
            url_personal: url_personal,
            url: url,
            url_admin: urlAdmin,
            observaciones: observaciones
        })
    },
    getEnterpriseCourseRelation: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/enterprises/enterprisecourse/${id}`)
    },
    relationEnterprisesCourses(id, courses) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/enterprises/enterprisecourse/${id}`, {
            courses: courses
        })
    },
    relationEnterpriseUser(id, users) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/enterprises/enterpriseuser/${id}`, {
            users: users
        })
    },

    postFile: function (formdata, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/enterprises/upload`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    enterpriseUrl: function (url, campo) {
        return axios.post(url_backend + `/api/enterprises/enterpriseUrl`, {
            campo: campo,
            url: url
        })
    },
    exportar: function (objeto) {
        //   axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objeto: objeto,
        }
        return axios({
            url: url_backend + `/api/enterprises/exportarExcel`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
}

export const coursesData = {
    getCourses: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/courses/courses`)
    },
    getCoursesReducido: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/courses/coursesReducido`)
    },
    getCoursesAll: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/courses/coursesall`)
    },
    getCoursesAllIni: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/courses/coursesallini`)
    },
    getCourse: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/courses/course/${id}`)
    },
    postFile: function (formdata, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/upload`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    }, preImgPerfilResize: function (datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/uploadResize`, datos, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    },
    postNewCourse: function (course) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/courses`, course)
    },
    getContent: function (document) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_media + `/${document}`, {
            responseType: 'blob'
        })
    },
    updateCourse: function (id, course) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/course/${id}`, course)
    },
    relationCoursesEnterprises(id, empresas) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/courseenterprise/${id}`, {
            empresas: empresas
        })
    },
    getCourseRelations(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/courses/courseenterprise/${id}`)
    },
    borrarCurso(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/deletecourse/${id}`)
    },
    hideCurso(id, oculto) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/hidecourse/${id}`, { oculto: oculto })
    },
    setEnviado: function (id, comunicacion) {
        const datos = {
            id: id,
            comunicacion: comunicacion
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/matriculas/setEnviado`, datos)
    },
    duplicar: function (id) {
        const datos = {
            id: id
        }
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend + `/api/courses/duplicar`, datos)
    },
    exportar: function (objeto) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objeto: objeto,
        }
        return axios({
            url: url_backend + `/api/courses/exportarExcel`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    generaCatalogo: function () {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios({
            url: url_backend + `/api/courses/generaCatalogo`,
            method: 'POST',
            responseType: 'blob', // important
            //data: datos
        })
    }
}

export const matriculasData = {
    getMatriculas() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculas`)
    },
    getMatriculasFirst() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculasFirst`)
    },
    getMatriculasFacturacion() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculasFacturacion`)
    },
    getMatriculasDeleted() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculasDeleted`)
    },
    getMatricula(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matricula/${id}`)
    },
    getMatriculaResumen(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculaResumen/${id}`)
    },
    getMatriculaResumen2(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculaResumen2/${id}`)
    },
    getMatriculasGrupo(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/getMatGrupos/${id}`)
    },
    getUsersCursoEmpresa: function (empresa, curso) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/matriculas/getUsersCursoEmpresa`, { empresa: empresa, curso: curso })
    },
    getMatriculasTutor: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/getMatriculasTutor/${id}`)
    },
    getMatriculasAuditor: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/getMatriculasAuditor/${id}`)
    },
    getSeguimiento: function (matriculaId) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/dameSeguimientoMatricula/${matriculaId}`)
    },
    postMatricula(enterprise_id, user_id, course_id, fecha_inicio, fecha_fin, horas_estimadas, dia_accesos, hora_inicio_accesos, hora_fin_accesos, num_expediente,
        num_accion,
        cod_aaff,
        modalidad,
        cif_declarar,
        perfil,
        num_grupo,
        accion,
        horas,
        denomin_accion,
        alias_curso,
        enlace,
        info_ad_txt,
        info_ad_document, comMatricula, tutMatricula, audMatricula, cerMatricula, alumnos, codificacion, tarifa, acceso_libre, tutorCert, deshabilitar_descarga) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            enterprise_id: enterprise_id,
            user_id: user_id,
            course_id: course_id,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
            horas_estimadas: horas_estimadas,
            dia_accesos: dia_accesos,
            hora_inicio_accesos: hora_inicio_accesos,
            hora_fin_accesos: hora_fin_accesos,
            cerMatricula: cerMatricula,

            num_expediente: num_expediente,
            num_accion: num_accion,
            cod_aaff: cod_aaff,
            modalidad: modalidad,
            cif_declarar: cif_declarar,
            perfil: perfil,
            num_grupo: num_grupo,
            accion: accion,
            horas: horas,
            denomin_accion: denomin_accion,
            alias_curso: alias_curso,
            enlace: enlace,
            info_ad_txt: info_ad_txt,
            info_ad_document: info_ad_document,

            comMatricula: comMatricula,
            tutMatricula: tutMatricula,
            audMatricula: audMatricula,
            alumnos: alumnos,
            codificacion: codificacion,
            tarifa: tarifa,
            acceso_libre: acceso_libre,
            tutor_certificacion: tutorCert,
            deshabilitar_descarga: deshabilitar_descarga
        }
        return axios.post(url_backend + `/api/matriculas/matricula`, datos)
    },
    editMatricula(enterprise_id, course_id, fecha_inicio, fecha_fin, horas_estimadas, dia_accesos, hora_inicio_accesos, hora_fin_accesos, num_expediente,
        num_accion,
        cod_aaff,
        modalidad,
        cif_declarar,
        perfil,
        num_grupo,
        accion,
        horas,
        denomin_accion,
        alias_curso,
        enlace,
        info_ad_txt,
        info_ad_document, comMatricula, tutMatricula, audMatricula, cerMatricula, grupo, codificacion, tarifa, acceso_libre, tutorCert, deshabilitar_descarga) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")

        const datos = {
            enterprise_id: enterprise_id,
            course_id: course_id,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
            horas_estimadas: horas_estimadas,
            dia_accesos: dia_accesos,
            hora_inicio_accesos: hora_inicio_accesos,
            hora_fin_accesos: hora_fin_accesos,
            cerMatricula: cerMatricula,

            num_expediente: num_expediente,
            num_accion: num_accion,
            cod_aaff: cod_aaff,
            modalidad: modalidad,
            cif_declarar: cif_declarar,
            perfil: perfil,
            num_grupo: num_grupo,
            accion: accion,
            horas: horas,
            denomin_accion: denomin_accion,
            alias_curso: alias_curso,
            enlace: enlace,
            info_ad_txt: info_ad_txt,
            info_ad_document: info_ad_document,

            comMatricula: comMatricula,
            tutMatricula: tutMatricula,
            audMatricula: audMatricula,
            codificacion: codificacion,
            acceso_libre: acceso_libre,
            tutor_certificacion: tutorCert,
            deshabilitar_descarga: deshabilitar_descarga
        }
        return axios.post(url_backend + `/api/matriculas/editMatricula/${grupo}`, datos)
    },
    desmatricular(alumnos, curso, grupo) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            alumnos: alumnos,
            curso: curso,
            grupo: grupo,
        }
        return axios.post(url_backend + `/api/matriculas/desmatricular`, datos)
    },
    getMatriculasEmpresa(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/matriculasenterprise/${id}`)
    },
    postFile: function (formdata, onUploadProgress) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/matriculas/upload`, formdata, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    generaPDF: function (id, fecha_ini, fecha_fin) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            fecha_ini: fecha_ini,
            fecha_fin: fecha_fin
        }
        return axios({
            url: url_backend + `/api/matriculas/pdf/${id}`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    generaPDFLand: function (id, fecha_ini, fecha_fin) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            fecha_ini: fecha_ini,
            fecha_fin: fecha_fin
        }
        return axios({
            url: url_backend + `/api/matriculas/pdfLand/${id}`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    generaPDFZip: function (id, fecha_ini, fecha_fin) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            fecha_ini: fecha_ini,
            fecha_fin: fecha_fin
        }
        return axios({
            url: url_backend + `/api/matriculas/pdfZip/${id}`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    generaPDFLandZip: function (id, fecha_ini, fecha_fin) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            fecha_ini: fecha_ini,
            fecha_fin: fecha_fin
        }
        return axios({
            url: url_backend + `/api/matriculas/pdfLandZip/${id}`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    generaTodos: function (lista) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            lista: lista
        }
        return axios({
            url: url_backend + `/api/matriculas/generaTodos`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    generaTodosLand: function (lista) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            lista: lista
        }
        return axios({
            url: url_backend + `/api/matriculas/generaTodosLand`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    descargaZip: function (lista, ini) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            lista: lista,
            ini: ini
        }
        return axios({
            url: url_backend + `/api/matriculas/descargaZip`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    descargaPDF: function (name) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios({
            url: url_backend + `/api/media/pdf/${name}`,
            method: 'GET',
            responseType: 'blob', // important
        })
    },
    getDiplomas: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/matriculas/diplomas`)
    },
    sendBienvenido: function (nombre, mail, link, curso, horas, matricula_id) {
        const datos = {
            nombre: nombre,
            email: mail,
            link: link,
            curso: curso,
            horas: horas,
            logo: localStorage.getItem("logo"),
            matricula_id: matricula_id,
        }
        return axios.post(url_backend + `/api/comunicaciones/bienvenido`, datos)
    },
    sendBienvenidoAvsaf: function (nombre, mail, link, curso, horas, matricula_id) {
        const datos = {
            nombre: nombre,
            email: mail,
            link: link,
            curso: curso,
            horas: horas,
            logo: localStorage.getItem("logo"),
            matricula_id: matricula_id,
        }
        return axios.post(url_backend + `/api/comunicaciones/bienvenidoAvsaf`, datos)
    },
    getMatriculaComu: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        let aux = parseInt(id)
        return axios.get(url_backend + `/api/matriculas/getMatComunicaciones/${aux}`)
    },
    getMatriculaRecordatorios: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        let aux = parseInt(id)
        return axios.get(url_backend + `/api/matriculas/getMatriculaRecordatorios/${aux}`)
    },
    exportar: function (objecto) {
        //   axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objecto: objecto,
        }
        return axios({
            url: url_backend + `/api/matriculas/exportarExcel`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    exportarMat: function (objeto) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objeto: objeto,
        }
        return axios({
            url: url_backend + `/api/matriculas/exportarExcelMat`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    exportarAlu: function (objeto) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objeto: objeto,
        }
        return axios({
            url: url_backend + `/api/matriculas/exportarExcelAlu`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    exportarSelExcel: function (objeto) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            objeto: objeto,
        }
        return axios({
            url: url_backend + `/api/matriculas/exportarSelExcel`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    informeSeguimiento: function (matricula) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            matricula: matricula,
        }
        return axios({
            url: url_backend + `/api/matriculas/informeSeguimiento`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    informeSeguimientoMulti: function (matriculas) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            matriculas: matriculas,
        }
        return axios({
            url: url_backend + `/api/matriculas/informeSeguimientoMulti`,
            method: 'POST',
            //responseType: 'blob', // important
            data: datos
        })
    },
    descargaZipSeg: function (lista) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            lista: lista
        }
        return axios({
            url: url_backend + `/api/matriculas/descargaZipSeg`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    userMatriculasComunicaciones: function (ids) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            ids: ids,
        }
        return axios.post(url_backend + `/api/matriculas/userMatriculasComunicaciones`, datos)
    },
    userMatriculasComunicacionesAvsaf: function (ids) {
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const datos = {
            ids: ids,
        }
        return axios.post(url_backend + `/api/matriculas/userMatriculasComunicacionesAvsaf`, datos)
    },
    enviarRecordatorio: function (userId, cursoId, matriculaId) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            userId: userId,
            cursoId: cursoId,
            matriculaId: matriculaId,
            logo: localStorage.getItem("logo"),
        }
        return axios.post(url_backend + `/api/matriculas/enviarRecordatorio`, datos)
    },
    enviarRecordatorioMulti: function (cursoId, matriculas) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            cursoId: cursoId,
            matriculas: matriculas,
            logo: localStorage.getItem("logo"),
        }
        return axios.post(url_backend + `/api/matriculas/enviarRecordatorioMulti`, datos)
    },
    eliminaGrupo : function (matricula) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            grupoId: matricula.id
        }
        return axios.post(url_backend + `/api/matriculas/eliminaGrupo`, datos)
    }
}

export const comunicacionesData = {
    getComunicaciones: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/comunicaciones/comunicaciones`)
    },
    getImagen: function (image) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/media/documents/${image}`)
    },
    sendMail: function (titulo, texto) {
        const datos = {
            titulo: titulo,
            texto: texto
        }
        return axios.post(url_backend + `/api/comunicaciones/peticionComunicacion`, datos)
    }
}

export const certificacionesData = {
    getCertificaciones: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/certificaciones/certificaciones`)
    },
    getTipoPosicion: function(tipo_posicion){
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/certificaciones/tipo_posicion`)
    },
    getCertificacion: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/certificaciones/certificacion/${id}`)
    },
    getImagen: function (image) {
        return axios.get(url_backend + `/api/media/documents/${image}`)
    },
    sendMail: function (titulo, texto) {
        const datos = {
            titulo: titulo,
            texto: texto
        }
        return axios.post(url_backend + `/api/comunicaciones/peticionComunicacion`, datos)
    },
    preImgPerfilResize: function (datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/certificaciones/uploadResize`, datos, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    },
    resizeFirma: function (datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/certificaciones/uploadResizeFirma`, datos, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    },
    update: function (id, titulo, logo, img_firma, usuario_firmante, encabezado, contenido, tipo_curso, cargo_firmante, textoCampo, tipo, columnas, tipo_posicion) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            titulo: titulo,
            logo: logo,
            img_firma: img_firma,
            usuario_firmante: usuario_firmante,
            encabezado: encabezado,
            contenido: contenido,
            tipo_curso: tipo_curso,
            cargo_firmante: cargo_firmante,
            texto_curso: textoCampo,
            tipo_certificado: tipo,
            columnas: columnas,
            tipo_posicion: tipo_posicion
        }
        return axios.post(url_backend + `/api/certificaciones/update/${id}`, datos)
    },
    updateOculta(id, oculta) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/certificaciones/updateOculta/${id}`, {
    
            oculta:oculta
        })
    },
    eliminarCertificado(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/certificaciones/eliminarCertificado/${id}`)
    },
    insert: function (titulo, logo, img_firma, usuario_firmante, encabezado, contenido, tipo_curso, cargo_firmante, textoCampo, tipo, columnas, tipo_posicion) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            titulo: titulo,
            logo: logo,
            img_firma: img_firma,
            usuario_firmante: usuario_firmante,
            encabezado: encabezado,
            contenido: contenido,
            tipo_curso: tipo_curso,
            cargo_firmante: cargo_firmante,
            texto_curso: textoCampo,
            tipo_certificado: tipo,
            columnas: columnas,
            tipo_posicion: tipo_posicion
        }
        return axios.post(url_backend + `/api/certificaciones/insert`, datos)
    },
    getTiposCertificados: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/certificaciones/tipos`)
    }
}

export const quizGeneralesData = {
    updateQuiz: function (id, quiz) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/cuestionarios_generales/cuestionario/${id}`, quiz)
    },
    postNewQuiz: function (quiz) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/cuestionarios_generales/cuestionario`, quiz)
    },
    getQuizs: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/cuestionarios_generales/cuestionario`)
    },
    getQuiz: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/cuestionarios_generales/cuestionario/${id}`)
    },
    eliminarGeneral: function (id, objeto) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/cuestionarios_generales/borrarcuestionario/${id}`, objeto)
    }
}

export const mensajesData = {
    dameMensajesIni: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/mensajes/mensajesIni/${id}`)
    },
    dameMensajesConver: function (id, usuario) {
        const user = {
            mi_id: parseInt(id),
            user_id: usuario
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/mensajes/mensajesConver`, user)
    },
    guardaRedMensaje2: function (id, destinatario, texto, tipo, adjuntos, idCurso) {
        const user = {
            texto: texto,
            receptor_id: destinatario,
            emisor_id: id,
            tipo_id: tipo,
            idCurso: idCurso,
            adjuntos: adjuntos
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/mensajes/nuevo-mensaje`, user)
    },
    guardaRedMensaje3: function (id, destinatario, texto, tipo, adjuntos, idCurso) {
        const user = {
            texto: texto,
            receptor_id: destinatario,
            emisor_id: id,
            tipo_id: tipo,
            idCurso: idCurso,
            adjuntos: adjuntos
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/mensajes/nuevo-mensajeAadmin`, user)
    },
    guardaRedMensaje: function (id, destinatario, texto, tipo, adjuntos) {
        const user = {
            texto: texto,
            receptor_id: destinatario,
            emisor_id: id,
            tipo_id: tipo,
            adjuntos: adjuntos
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/mensajes/nuevo-mensajeAux`, user)
    },
    uploadMsj: function (datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/mensajes/uploadMsj`, datos, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    },
    leerMensajes(cod, user) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const data = {
            cod: cod,
            user: user
        }
        return axios.post(url_backend + `/api/mensajes/leerMensajes`, data)
    },
}



export const categories = {
    saveCategorie: function (nombre, codificacion) {
        return axios.post(url_backend + `/api/categorias/guardar`, { nombre: nombre, codificacion: codificacion })
    },

    getCategories: function () {
        return axios.get(url_backend + `/api/categorias/show`)
    },

    deleteCategorie: function (id) {
        return axios.post(url_backend + `/api/categorias/delete/${id}`)
    },
    updateCategorie: function (id, nombre, codificacion) {
        return axios.post(url_backend + `/api/categorias/update/${id}`, { nombre: nombre, codificacion: codificacion })
    }

}