import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {userProfile, loginURL} from './data/Data'
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Autologin = React.lazy(() => import('./views/pages/login/Autologin'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ResetPass = React.lazy(() => import('./reset-password'));
const FirstChange = React.lazy(() => import('./views/FirstChange'));
const rol = localStorage.getItem('rol');

const pathroute = window.location.pathname;
function compruebaKey() {
  if(localStorage.getItem('auth') === null) {
    return false;
  }
  return true;
}
class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoggued: false
    }
  }

  componentDidMount(){
    if(pathroute != '/autologin') {
      if(!localStorage.getItem('auth')){
        localStorage.removeItem('auth')
        this.setState({isLoggued: false})

      }else{
        userProfile.getProfile()
        .then(() => {
          //console.log('app')
          this.setState({isLoggued: true})
        })
        .catch(error =>  {
          localStorage.removeItem('auth')
          this.setState({isLoggued: false})
          window.location.href = loginURL;
        })
        
      }
    }
  }


  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {/*<Route exact path="/">
                  <Redirect to={compruebaKey() ? '/dashboard' : '/login'} />
              </Route>
              {compruebaKey() === false && 
                <Route exact path={pathroute != '/login' && pathroute != '/reset-password' ? pathroute : '/'}>
                    <Redirect to="/login" />
                </Route>
              }
              {compruebaKey() === true && 
                <Route exact path={pathroute == '/login' || pathroute == '/reset-password' ? pathroute : '/login'}>
                    <Redirect to="/dashboard" />
                </Route>
              }*/}
            {/*<Route exact path={window.location.pathname}>
                <Redirect to={compruebaKey() ? '/dashboard' : '/login'} />
            </Route>*/}
            {/*compruebaKey() === false && 
                <Route exact path={pathroute != '/login' && pathroute != '/reset-password' ? pathroute : '/'}>
                    <Redirect to="/login" />
                </Route>
            */}
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/autologin" name="Autologin Page" render={props => <Autologin {...props}/>} />
              <Route exact path="/reset-password" name="Resetpass Page" render={props => <ResetPass {...props}/>} />
              <Route exact path="/change-password" name="Change Pass" render={props => <FirstChange {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
